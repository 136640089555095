.contact-page .contact-card{
    
}
/* .middle-bar{
    min-height: 200px;
    background: var(--red);
    width: 100%;
} */

.contact-page{
    position: relative;
    min-height: 80vh;
    justify-content: center;
}
.contact-page ul{
    padding: 0;
    list-style-type: none;
}
.contact-page ul li{
    margin-bottom: 15px;
    font-size: 1.1rem;
}
.contact-page ul li i{
    color:var(--red)
}
