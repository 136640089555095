.footer-div ul{
    padding: 0px;
    list-style-type: none;
}
.footer-div ul li{
    margin-bottom: 10px;
}
.footer-div ul li a{
    text-decoration: none;
    font-size: 1rem;
    color: #fff;
}
.footer-div h1{
    position: relative;
    margin-bottom: 30px;
}
.footer-div h1::before{
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0px;
    width: 60%;
    height: 3px;
    background-color: var(--red);
}